<template>
    <div class="static">
        <loading v-if="isLoading"/>
        <template v-if="!isLoading">
            <v-toolbar color="primary" dark flat>
                <v-btn icon @click="goBack">
                    <v-icon large>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>{{getLocalizedValue(staticPage.title)}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card flat>
                <v-card-text>
                    <div v-html="getLocalizedValue(staticPage.content)"></div>
                </v-card-text>
            </v-card>
        </template>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'StaticDetail',
    mixins: [GetLocalizedValue],
    data: () => ({
        isLoading: false,
        isError: false
    }),
    computed: {
        ...mapState('staticPages', ['staticPage'])
    },
    methods: {
        ...mapMutations('staticPages', ['setStaticPageFromStorage']),
        goBack() {
            this.$router.back();
        },
        fetchStaticPage() {
            this.isLoading = true;
            this.$store.dispatch('staticPages/getPageById', this.$route.params.id).then((response) => {
                this.isLoading = false;
                this.isError = false;
            }).catch(() => {
                this.isError = true;
            });
        },
        fetchStaticPageFromStorage() {
            this.isLoading = true;
            this.setStaticPageFromStorage(this.$route.params.id);
            this.isLoading = false;
        }
    },
    created() {
        if (this.isOnline) {
            this.fetchStaticPage();
        }
        if (!this.isOnline) {
            this.fetchStaticPageFromStorage();
        }
    }
};
</script>
